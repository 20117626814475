<template>
  <!-- <div class="" > -->
  <div class="notificationcontent">
    <!--  -->
    <!-- <b-button v-b-toggle.sidebar-1>Toggle Sidebar</b-button> -->
    <div v-if="notifications.length">
      <!-- <b-sidebar id="sidebar-1"  :backdrop-variant="variant"
      backdrop
      shadow
      > -->
      <div class="">
        <!-- <div class="tab-content"> -->
        <!-- <b-tabs class="hide-tabs" v-model="tabIndex"> -->
        <!-- <b-tab active> -->
        <div class="d-flex pt-5">
          <div class="col-md-6 d-flex align-items-center justify-content-start">
            <div class="title">Notifications</div>
          </div>
          <div v-if="notifications.length > 0" class="col-md-6 d-flex align-items-center justify-content-end">
            <button class="clearbutton" @click="notificationtrash">ClearAll</button>
            <!-- <b-button variant="primary" @click="hide" style="background: none; border: none;margin-top: 17px;"><i
                        class="fa fa-times fa-2x" aria-hidden="true"></i></b-button> -->
            <!-- <a href="#" class="btn btn-info btn-lg">
                    <span class="glyphicon glyphicon-trash"></span> Trash 
                  </a> -->
          </div>
        </div>
        <div class="" id="">
          <div v-for="(group, date) in groupedItems" :key="date">
            <!-- <h2>{{ date }}</h2> -->
            <div
              style="
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                margin-left: 51px;
                margin-top: 19px;
                color: #ffffff;
              "
            >
              {{ date }}
            </div>
            <ul class="no-dot">
              <li v-for="item in group" :key="item.id">
                <div
                  class="align-items-center rounded-3 mb-5 bg-white mt-3"
                  v-bind:key="i"
                  style="margin-right: 42px; margin-left: 42px; border-radius: 33px"
                >
                  <div class="text-dark">
                    <div class="svg-icon mr-5" v-bind:class="`svg-icon-${item.category}`"></div>
                    <div class="d-flex">
                      <div class="col-xl-10 m-7">
                        <div>
                          <div class="msgtitle mb-3">
                            {{ item.title }}
                          </div>
                          <!-- <a
                          href="#"
                          class="font-weight-bold text-white-200 font-size-h1 mb-1"   
                          
                        >
                          {{ item.title }}
                        </a> -->
                          <div class="mb-5">
                            <p class="msg">{{ item.msg }}</p>
                          </div>
                        </div>

                        <div class="d-flex">
                          <div class="msgtime">
                            {{ formatDate(item.time) }}
                          </div>
                          <div v-if="item.route != ''" style="align-items: center; display: flex">
                            <a href="#">
                              <b-button
                                variant="primary view_button"
                                @click="
                                  $router.push({
                                    name: item.route,
                                    params: { id: item.id },
                                  })
                                "
                                >View</b-button
                              >
                            </a>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-2" style="display: flex; align-items: center; justify-content: center">
                        <div class="">
                          <button @click="notificationDelete(item.notification_id)">
                            <inline-svg
                              src="media/svg/icons/General/trash_icon.svg"
                              fill="#3D415B"
                              height="28"
                              width="28"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <span class="font-weight-bolder font-size-lg" v-bind:class="`text-${item.type}`">
                    {{ item.alt }}
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- </b-tab> -->

        <!-- </b-tabs> -->
        <!-- </div> -->
      </div>
      <!-- </b-sidebar> -->
    </div>
    <div v-else></div>
  </div>

  <!--  -->
  <!-- </div> -->
  <!-- end::Quick Panel -->
</template>

<script>
import KTLayoutQuickPanel from "@/assets/js/layout/extended/quick-panel.js";
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import Swal from "sweetalert2";

export default {
  name: "KTQuickPanel",
  props: ["refreshValue"],
  watch: {
    refreshValue: function () {
      this.getData();
    },
  },
  data() {
    return {
      tabIndex: 0,
      notifications: [],
    };
  },
  mounted() {
    // Init Quick Offcanvas Panel
    KTLayoutQuickPanel.init(this.$refs["kt_quick_panel"]);
    this.getData();
  },
  computed: {
    groupedItems() {
      return this.notifications.reduce((groups, item) => {
        const date = this.formatwise(item.time);
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(item);
        return groups;
      }, {});
    },
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    closeSidebar() {
      this.isOpen = false;
    },
    getData() {
      if (JwtService.getToken()) {
        Swal.fire({
          title: "Please Wait",
          allowOutsideClick: false,
          onOpen: () => {
            Swal.showLoading();
          },
        });

        ApiService.setHeader();
        ApiService.get("apps/notifications/readset")
          .then(({ data }) => {
            Swal.close();
            this.notifications = data.notifications;

            var value = new Date();
            this.$emit("notif-refresh", value);

            // if (this.notifications.length === 0) {
            //   this.$router.push({ name: "dashboard" });
            // }
          })
          .catch(({ response }) => {
            console.error(response);
            Swal.close();
          });
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat("en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric",
        weekday: "short",
        hour: "numeric",
        minute: "numeric",
        timeZone: "Europe/London",
      }).format(date);
      // month:'2-digit',day:'2-digit', year:'numeric',hour:'2-digit',minute:'2-digit'
      // {dateStyle: 'short'}
    },
    formatwise(dateString) {
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat("en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric",
        weekday: "short",
        timeZone: "Europe/London",
      }).format(date);
      // month:'2-digit',day:'2-digit', year:'numeric',hour:'2-digit',minute:'2-digit'
      // {dateStyle: 'short'}
    },
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    },
    notificationtrash() {
      Swal.fire({
        title: "Please Wait",
        allowOutsideClick: false,
        onOpen: () => {
          Swal.showLoading();
        },
      });

      ApiService.setHeader();
      ApiService.getblob("apps/notificationstrash")
        .then(() => {
          this.getData();
          Swal.close();
          this.$router.push({ name: "dashboard" });
        })
        .catch(() => {
          Swal.close();
          Swal.fire({
            title: "Sorry Notification Clear Error, Try Later",
            confirmButtonClass: "btn btn-secondary",
          });
        });
    },
    notificationDelete(msgId) {
      Swal.fire({
        title: "Are you sure you want to delete NotiFication?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.post("apps/notificationsdelete", { id: msgId }).then(() => {
            setTimeout(() => {
              this.getData();
            }, 1000);
          });
        }
      });
    },
  },
};
</script>

<style>
/* .offcanvas { width: 360px; height: 100%; position: fixed; top: 0; overflow: inherit; -webkit-transition: all 0.6s; transition: all 0.6s; z-index: 10000; } */

.offcanvas-size-xxl {
  /* width: 600px !important; */
  height: 10% !important;
}

.offcanvas-size-xxl {
  --bs-offcanvas-width: min(95vw, 90vw) !important;
}

.offcanvas-size-md {
  /* add Responsivenes to default offcanvas */
  --bs-offcanvas-width: min(95vw, 400px) !important;
}

.offcanvas-size-sm {
  --bs-offcanvas-width: min(95vw, 250px) !important;
}

/* .b-sidebar {
  width: 100%;
  height: 700vh;
}

.b-icon.bi {
    display: inline-block;
    overflow: visible;
    vertical-align: -0.15em;
    color: white;
}
.b-sidebar>.b-sidebar-header .close {
  font-size: 40px;
  color: white;
}

.scroll.ps>.ps__rail-y>.ps__thumb-y {
  top: 60px;
  height: 70px;
  width: 13px;
  /* background-color: #625656eb; */
/* }  */

.view_button {
  font-size: 17px;
  border-radius: 10px;
  margin-left: 17px;
  height: 31px;
  width: 81px;
  text-align: center;
  padding-top: 2px;
}

.scroll.ps > .ps__rail-x {
  top: 60px;
  height: 70px;
  width: 13px;
  /* background-color: #625656eb; */
}

/* .b-sidebar-backdrop { */

/* width: 94vw;
    margin-left: 101px;s
    height: 165vh;
    opacity: 70%;
    color: #101012;
} */
.notificationcontent {
  /* background-color: #101012; */
  /* background-color: #58585A !important; */
  /* opacity: 0.7 !important; */
  min-height: 100%;
  min-width: 100%;
}

/* .b-sidebar>.b-sidebar-body {
  padding-left: 128px;

} */

.title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
  color: #3d415b;
}

.msgtitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;

  color: #3d415b;
}

.msg {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;

  text-align: justify;
  color: #3d415b;
}

.content {
  padding: 0;
}

.msgtime {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */
  align-items: center;
  display: flex;
  color: #3d415b;
}

.no-dot {
  list-style: none;
}
.clearbutton {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #3d415b;
  border: none;
  background: none;
}
</style>
